/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.parent{ margin: auto;}
.myHeight{ height: 100vh; display: flex; align-items: center; text-align: center;}
.myCapitalized { text-transform: capitalize}
.logo-width {
	width: 200px !important;
}
.verfiction-input {
    font-size: 2.5rem !important;
	width: 1.5em !important;
}
@media only screen and (max-width: 576px) {
	.verfiction-input {
		font-size: 1.5rem !important;
	}
}
.bg-opacity-40 {
  --bs-bg-opacity: 0.4 !important;
}
.bg-opacity-70 {
  --bs-bg-opacity: 0.7 !important;
}
.p-zero {
	padding: 0.8rem !important;
}
.fs-18 {
	font-size: 18px !important;
}
.fs-22 {
	font-size: 22px !important;
}
.fs-24 {
	font-size: 24px !important;
}
.fs-28 {
	font-size: 30px !important;
}
.fs-30 {
	font-size: 30px !important;
}
.fs-36 {
	font-size: 36px !important;
}
.fw-600 {
	font-weight: 600 !important;
}
.fw-700 {
	font-weight: 700 !important;
}
.bg-dark {
	background-color: #1d2023 !important;
}
.custom-file-input {
	span:first-child {
		padding: 0.571rem 1rem;
		margin: -0.571rem -1rem;
		margin-inline-end: 1rem;
		color: #6e6b7b;
		background-color: #fff;
		pointer-events: none;
		border-color: inherit;
		border-style: solid;
		border-width: 0;
		border-inline-end-width: 1px;
		border-radius: 0;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
	}
}

.flatpickr-monthDropdown-months {
    appearance: menulist !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months span, .flatpickr-current-month .numInputWrapper span {
    display: block !important;
}